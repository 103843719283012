export const Privacy = () => <section className="content">
    <h2>隐私政策</h2>
    EasyAct云预算 非常重视用户的隐私权，具体细则如下：<br/>
    <h4>1. 隐私权政策适用范围</h4>
    <ol>
        <li>包括 EasyAct云预算 如何处理用户在登录其网站和服务器时留下的个人身份识别信息，以及与商业伙伴共享的其他身份识别信息。</li>
        <li>隐私权政策不适用于非 EasyAct云预算 所属机构及非 EasyAct云预算 雇员。</li>
    </ol>
    <h4>2. 信息收集和使用</h4>
    <ol>
        <li>
           在你注册 EasyAct云预算 账户，使用 EasyAct云预算 产品或服务，访问 EasyAct云预算 网页, 或参加促销和有奖游戏时， EasyAct云预算 会收集你的个人身份识别资料。
            EasyAct云预算
            也会收集来自商业伙伴的身份识别资料。
        </li>
        <li>
           当你注册 EasyAct云预算 账户时,我们会问及你的昵称、电邮地址。只要你在 EasyAct云预算 成功注册并登录服务器，我们将可以识别你。
        </li>
        <li>
           EasyAct云预算 会将这些资料用于：改进为你提供的广告及网页内容，满足你对某种产品的需求或通知你最新产品信息。
        </li>
    </ol>
    <h4>3. 信息公开与共享</h4>

    EasyAct云预算 不会将你的个人识别信息出租或出售给任何人，以下情况除外：<br/>
    <ol>
        <li>
           你同意让第三方共享资料；
        </li>
        <li>
           只有透露你的个人资料，才能提供你所要求的产品和服务；
        </li>
        <li>
           我们需要向代表我们提供产品或服务的公司提供资料（除非我们另行通知你，否则这些公司无权使用你的身份识别资料）；
        </li>
        <li>
           我们需要听从法庭传票、法律命令或遵循法律程序；
        </li>
        <li>
           我们发现你违反了 EasyAct云预算 服务条款或任何其他产品及服务的使用规定。
        </li>
    </ol>
    <h4>4. 编辑个人账户资料的权限</h4>
    <ol>
        <li>
            EasyAct云预算 赋予你在任何时候编辑 EasyAct云预算 账户信息的权利。
        </li>
    </ol>
    <h4>5. 安全保障</h4>

    你的 EasyAct云预算 账户具有密码保护功能，以确保你的隐私和信息安全。在某些情况下， EasyAct云预算 使用通行标准的SSL保密系统，确保资料传送的安全性。<br/>
    <h4>6. 隐私权政策的修订</h4>

    EasyAct云预算 时常会对隐私权政策进行修改。如果在使用用户个人信息政策方面有大幅度修改时，我们会在网页中显眼的位置贴出相关规定。<br/>
</section>

import * as R from 'ramda'
import {parseISO} from 'date-fns'

export const getMax = R.pipe(
    // ({date, ...i}) => i,
    R.values,
    R.groupBy(x => x > 0),
    R.values,
    R.map(R.pipe(R.sum, Math.abs)),
    R.apply(Math.max),
)

export const durationDict = R.pipe(
    R.map(R.props(['name', 'duration'])),
    R.fromPairs
)

export const getNameAndStart = R.pipe(
    R.props(['name', 'start']),
    ([name, start]) => [name, parseISO(start)],
    // log('getNameAndStart')
)

import List from './budget/List'
import {monthlyAmountCalc} from './budget/util'
import * as R from 'ramda'
import {BalanceSheet} from './BalanceSheet'

const AMOUNT = '数额'
const DURATION = 'duration'
const monthlyAddition = item => mainValue => {
    const calc = monthlyAmountCalc(DURATION, 'amount', item)
    return mainValue === calc ? null : calc.toFixed(0) + '/月'
}

const relatedForm = (relatedList, listName = '资产') => (editing, relatedId, setRelatedId) => {
    // console.log(l)
    const name = R.pipe(R.find(R.propEq('id', relatedId)), R.propOr('无', 'name',))(relatedList)
    return <div className="field is-horizontal">
        <div className="field-label is-small">
            <label className="label">关联{listName}</label>
        </div>
        <div className="field-body">
            <div className="field">
                {editing ? <div className="control">
                    <div className="select is-fullwidth is-small">
                        <select value={relatedId} onChange={e => setRelatedId(e.target.value)}>
                            <option>无</option>
                            {relatedList.map(item => <option
                                key={`assoc-${item.id}`}
                                value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                </div> : <p className="control"><small>{name}</small></p>}
            </div>
        </div>
    </div>
}

export default function Statement({budget, dispatch}) {
    return <div className="columns">
        <div className="column">
            <List name={'incomes'} title="收入" items={budget.incomes}
                  dispatch={dispatch}
                  columns={[{title: '收入', type: 'text', key: 'name'}
                      , {
                          title: AMOUNT, type: 'number', key: 'amount',
                          addition: monthlyAddition
                      }
                      , {title: '周期', type: DURATION, key: DURATION}]}
                  detail={relatedForm(budget.assets)}
            />
            <List name={'expenses'} title="支出" items={budget.expenses}
                  dispatch={dispatch}
                  columns={[{title: '支出', type: 'text', key: 'name'}
                      , {
                          title: AMOUNT,
                          type: 'number',
                          key: 'amount',
                          addition: monthlyAddition
                      }
                      , {title: '周期', type: DURATION, key: DURATION}]}
                  detail={relatedForm(budget.liabilities, '负债')}
            />
        </div>
        <BalanceSheet budget={budget} dispatch={dispatch}/>
    </div>
}

import getDaysInYear from 'date-fns/fp/getDaysInYear'
import {range} from 'fp-ts/NonEmptyArray'
import addDays from 'date-fns/addDays'
import * as R from 'ramda'
import {Semigroup} from 'fp-ts/lib/Semigroup'
import {isAfter, isBefore, isSameDay} from 'date-fns/fp'
import {parseISO} from 'date-fns'
import {Item} from '../components/budget/Model'

export const curryAddDays = R.curry(addDays)

export function dateRange(start = new Date(), days = getDaysInYear(start)): Date[] {
    const addDaysToStart = curryAddDays(start)
    return range(0, days).map(addDaysToStart)
}

export const semigroupDailyData: Semigroup<any> = {
    concat: (x: any, y: any) => R.mergeWith(R.add, x, y)
}

export const notAfterNonFn = (date: Date, compare: Date) => R.complement(isAfter(compare))(date)
export const notAfter1 = (compare: Date) => R.complement(isAfter(compare))
// const isAfter0: (compare: Date) => (date: Date) => boolean = isAfter
export const notAfter = R.pipe(isAfter, R.complement)
export const notBefore = R.pipe(isBefore, R.complement)

const getDate = (prop: string) => R.pipe(R.prop(prop), parseISO)

export function validOn(date: Date): (a: Item) => boolean {
    return R.allPass([
        R.pipe(R.prop<Date>('startDate'), isSameDay(date)),
        // @ts-ignore
        R.pipe(getDate('start'), notAfter(date)),
        // @ts-ignore
        R.pipe(getDate('end'), notBefore(date))
    ])
}

import useScript from '../../hooks/useScript'

export function Price() {
    gtag('event', 'conversion', {'send_to': 'AW-1039816295/0NGXCLi0tdcCEOes6e8D'})
    useScript(process.env.REACT_APP_SAASPORT_URL)
    // useScript('https://saas-port.com.s3-website.cn-northwest-1.amazonaws.com.cn/widget.js')
    return <div>
        <div className="container" id="saasport"></div>
    </div>
}

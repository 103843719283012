import List from './budget/List'

export function BalanceSheet({budget, dispatch}) {
    return <div className="column">
        <List items={budget.assets} dispatch={dispatch} hint="带来被动收入"/>
        <List name={'liabilities'} title="负债" hint="从口袋掏钱出去" items={budget.liabilities}
              dispatch={dispatch}
              columns={[{title: '负债', type: 'text', key: 'name'}, {
                  title: '总数',
                  type: 'number',
                  key: 'amount'
              }, {title: '已还期数/总期数', type: 'text', key: 'amortized'}]}/>
    </div>
}

import {useState} from 'react'
import {dateRange, semigroupDailyData, validOn} from '../util/Viz'
import * as R from 'ramda'
import {reduce} from 'fp-ts/Array'
import {add, isBefore, parseISO} from 'date-fns'
import subMonths from 'date-fns/subMonths'
import {
    area,
    axisRight,
    axisTop,
    scaleLinear,
    scaleOrdinal,
    scaleTime,
    select,
    stack,
    stackOffsetDiverging,
    stackOffsetNone
} from 'd3'
import 'd3-transition'
import {legendColor} from 'd3-svg-legend'
import {format} from 'date-fns/fp'
import {getMax} from '../viz/util'
import useLongPress from './budget/hook/useLongPress'

function toStackKeys(items) {
    return R.map(({name, t}) => name + t)(items || [])
}

const generateSeries = (items, init) => reduce({arr: [init], next: items, max: 0},
    ({arr, next, max}, date) => {
        const dailyData = R.pipe(
            R.filter(validOn(date)),
            R.map(i => [i.name + i.t, i.amount]),
            R.fromPairs,
        )(next)
        const {date: _, ...last} = R.last(arr) || {}
        const cur = semigroupDailyData.concat(last, dailyData)
        const newNext = next.map(R.when(
            ({name, t}) => dailyData.hasOwnProperty(name + t),
            item => ({...item, startDate: add(date, item.duration)})
        ))
        return {arr: [...arr, {...cur, date}], max: R.pipe(getMax, R.max(max))(cur), next: newNext}
    })

function toStackData(budget = {}) {
    const negateAmounts = R.over(R.lensProp('amount'), R.negate)
    const expenses = (budget.expenses || []).map(negateAmounts).map(R.mergeLeft({t: ''}))
    const items = [...(budget.incomes || []).map(R.mergeLeft({t: '+'})), ...expenses,]
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    // const start = subMonths(today, 3)
    const start = budget.start ? parseISO(budget.start) : subMonths(today, 3)
    // console.log('budget.start', budget.start, start)
    const dates = dateRange(start)
    const day1 = dates[0]
    const addStartDate = item => {
        let startDate = parseISO(item.start)
        while (isBefore(startDate, day1)) {
            startDate = add(startDate, item.duration)
        }
        return {...item, startDate}
    }

    // noinspection NonAsciiCharacters
    const 存款 = R.sum(
        R.map(R.pipe(
            R.propOr(0, 'downPayment'),
            R.unless(R.is(Number), parseFloat),
        ))(budget.assets ?? [])
    )
    // noinspection NonAsciiCharacters
    const {arr: data, max} = generateSeries(items.map(addStartDate), {存款, date: day1})(dates)
    // console.log('data', data)
    return {data, keys: ['存款', ...toStackKeys(items)], max}
}

const fontSize = 10

const preventFirst = func => event => {
    event.preventDefault()
    func(event)
}

function CursorLine({width, height, stackLayout, data, yScale, dispatch, featureToggle}) {
    const [x, setX] = useState(100)
    const [y, setY] = useState(100)
    const [highLight, setHighLight] = useState()
    // const index = scaleLinear().domain([0, data.length]).range([0, width]).invert(x)
    const index = x / (width / data.length)
    const snapshot = data[Math.floor(index)]
    // console.log('snapshot', snapshot)
    const {date, ...datum} = snapshot
    const layout = stackLayout([datum])
    const setCoord = e => {
        if ('touches' in e) {
            e = e.touches[0]
        }
        const ctm = e.target.getScreenCTM()
        const clientX = e.clientX
        const clientY = e.clientY
        const x = (clientX - ctm.e) / ctm.a
        const y = (clientY - ctm.f) / ctm.d
        console.log(ctm.f, e, x, y)
        setX(x || 0)
        setY(y || 0)
    }

    function setHighLightAndXY(i, e) {
        setHighLight(i)
        setCoord(e)
    }
    const viewSnapshot = e => {
        setCoord(e)
        featureToggle.viewSnapshot && dispatch({type: 'VIEW_SNAPSHOT', payload: {snapshot}})
    }
    const longPress = useLongPress(viewSnapshot, setCoord)

    // console.log('x', x, index, datum, layout)
    const points = layout.map(({'0': d, key, index: i}) => <circle
        key={`point${key}`} cx={x} cy={yScale(d[1] || d[0])}
        r={highLight === i ? 4 : 3}
        onMouseOver={preventFirst(e => setHighLightAndXY(i, e))}
        onMouseMove={preventFirst(e => setHighLightAndXY(i, e))}
        onTouchStart={preventFirst(() => setHighLight(i))}
        onTouchMove={preventFirst(() => setHighLight(i))}
        onTouchEnd={preventFirst(() => setHighLight(i))}
        onTouchCancel={preventFirst(() => setHighLight(i))}
        onMouseDown={preventFirst(e => {
            setHighLight(highLight ? null : i)
            viewSnapshot(e)
        })}
        fill={highLight === i ? 'white' : 'light-green'}
        stroke={highLight === i ? 'black' : 'white'}
    />)
    const texts = layout.map(({'0': d, key, index: i}) => {
        // console.log(d, name, i, layout)
        const amount = d.data[key]
        const offset = i % 2 * -100
        const textX = 10 + x + (highLight === i ? -150 : offset)
        return <text key={`text${i}`} x={textX} y={yScale(d[1] || d[0])}
                     fontSize={`${highLight === i ? fontSize << 2 : fontSize}px`}
                     fill={highLight === i ? '#ffffff' : 'black'}
                     stroke={highLight === i ? 'black' : null}
        >
            {key}:{amount?.toFixed(2)}
        </text>
    })
    return <g id="cursorLine" width={width} height={height}>
        {texts}
        <line x1={x} y1={0} x2={x} y2={height} style={{stroke: 'black'}} strokeDasharray="5, 5"/>
        <line x1={0} y1={y} x2={width} y2={y} style={{stroke: 'black'}} strokeDasharray="5, 5"/>
        <text x={50} y={y + 15}>{yScale.invert(y).toFixed(0)}</text>
        <rect x={0} y={0} width={width} height={height} opacity={0}
              onMouseMove={preventFirst(event => setCoord(event))}
              onMouseOver={preventFirst(event => setCoord(event))}
              onTouchMove={preventFirst(event => setCoord(event))}
              // onMouseDown={viewSnapshot}
              {...longPress}
        />
        {points}
    </g>
}

export function StreamViz(
    {
        budget,
        width = window.innerWidth,
        height = 500,
        overlaying,
        dispatch,
        featureToggle,
    }) {
    const {data, keys, max} = toStackData(budget)
    const stackLayout = stack().keys(keys).offset(overlaying ? stackOffsetNone : stackOffsetDiverging)
    const xScale = scaleTime().domain([data[0].date, R.last(data)?.date]).range([0, width])
    const yScale = scaleLinear().domain([-max, max]).range([height, 0])
    const stackArea = area()
        .x(d => xScale(d.data.date))
        .y0(d => yScale(d[0]))
        .y1(d => yScale(d[1] || 0))
    // .curve(curveBasis)
    // const colorScale = scaleLinear()
    //     .range(['#06D1B2', '#ffffff'])

    const fillScale = scaleOrdinal()
        .domain(keys)
        .range(['#fcd88a', '#cf7c1c', '#93c464', '#75734F', '#5eafc6', '#41a368'])
    const stacks = stackLayout(data)
        // .map(log('stackLayout'))
        .map((d, i) => <path id={d.key} key={`stack${i}`} d={stackArea(d)} style={{
            fill: fillScale(d.key), stroke: 'black', strokeOpacity: 0.25, opacity: .5
        }}/>)
    const xAxis = axisTop().scale(xScale).tickFormat(format('yy/M'))
    const yAxis = axisRight().scale(yScale)
    const LEGEND_WIDTH = width / keys.length
    const legendA = legendColor().scale(fillScale).orient('horizontal').shapeWidth(LEGEND_WIDTH)
        // .labelAlign("start")
        .labels(({i, generatedLabels,}) => generatedLabels[i].split('').join(' '))
        .labelWrap(LEGEND_WIDTH)
    const callRef = f => node => node && select(node).call(f)
    return <svg width={width} height={height} style={{overflowX: 'auto'}}>
        <g>{stacks}</g>
        <g id="legend" ref={callRef(legendA)} transform="translate(0, 0)"/>
        <g id="xAxisG" ref={callRef(xAxis)} transform={`translate(0,${height})`}/>
        <g id="yAxisG" ref={callRef(yAxis)}/>
        <CursorLine width={width} height={height} data={data} stackLayout={stackLayout} yScale={yScale}
                    dispatch={dispatch} featureToggle={featureToggle}/>
    </svg>
}

import Statement from './Statement'
import useBudget from './budget/hook/useBudget'
import {useEffect, useReducer} from 'react'
import {updateBudget} from './budget/service/snapshot'

export default function FinalAccountsView() {
    const [{budget}] = useBudget('current')
    const [statement, dispatch] = useReducer(updateBudget, undefined, () => budget)
    useEffect(() => {
        dispatch({type: 'IMPORT_BUDGET', payload: budget})
    }, [budget])
    return <div>
        <div className="field has-addons">
            <div className="control">
                <input className="input" type="date" value={new Date().toISOString().split('T')[0]} placeholder="日期"/>
            </div>
            <div className="control">
                <a className="button is-info" href="/">
                    完成
                </a>
            </div>
        </div>
        <Statement budget={statement} dispatch={dispatch}/>
    </div>
}
